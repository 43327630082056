module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"linkImagesToOriginal":false,"maxWidth":700}},"gatsby-remark-embedder","gatsby-remark-copy-linked-files",{"resolve":"gatsby-remark-autolink-headers","options":{"className":"autolink-header","isIconAfterHeader":true}},{"resolve":"gatsby-remark-vscode","options":{"theme":"Community Material Theme Palenight","extensions":["/home/runner/work/syntaxsugar.io/syntaxsugar.io/vendor/blzjns.vscode-raml-3.0.1.vsix","/home/runner/work/syntaxsugar.io/syntaxsugar.io/vendor/coenraads.bracket-pair-colorizer-1.0.61.vsix","/home/runner/work/syntaxsugar.io/syntaxsugar.io/vendor/dataweave.data-weave-0.1.1.vsix","/home/runner/work/syntaxsugar.io/syntaxsugar.io/vendor/equinusocio.vsc-community-material-theme-1.4.2.vsix"]}},{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"noreferrer"}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
